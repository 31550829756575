import React from "react";
import {
  Box,
  Typography,
  Paper,
  Avatar,
  Rating,
  useTheme,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; // Import slick styles
import "slick-carousel/slick/slick-theme.css";

const Testimonials: React.FC = () => {
  const theme = useTheme();

  // Slick carousel settings for continuous smooth scrolling
  const settings = {
    infinite: true,
    speed: 5000, // Slower speed for smooth sliding
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Set to 0 for continuous sliding
    cssEase: "linear", // Linear easing for smooth continuous motion
    pauseOnHover: false, // Prevent pausing when hovering over a slide
    swipeToSlide: true, // Allow users to swipe between slides
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2, // Show 2 slides for medium screens
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Show 1 slide for small screens
        },
      },
    ],
  };

  const reviews = [
    {
      name: "Patient A",
      review: "Great service, friendly staff. Highly recommended!",
      image: "https://images.pexels.com/photos/417074/pexels-photo-417074.jpeg?cs=srgb&dl=pexels-souvenirpixels-417074.jpg&fm=jpg",
      rating: 4.5,
    },
    {
      name: "Patient B",
      review:
        "Highly professional and caring physicians. They really listened to my concerns.",
      image: "https://hips.hearstapps.com/pop.h-cdn.co/assets/cm/15/05/54cae423e713b_-_american-muscle-facts-06-0312-xln.jpg?crop=1xw:0.991304347826087xh;center,top&resize=980:*",
      rating: 5,
    },
    {
      name: "Patient C",
      review:
        "Highly professional and caring physicians. They really listened to my concerns.",
      image: "https://media.istockphoto.com/id/513392620/photo/big-dog.jpg?s=612x612&w=0&k=20&c=YgiuJ9_3LGYwHB40IVMnjwv8p-4RCFLwcD-yJAOYAGE=",
      rating: 4,
    },
    {
      name: "Patient D",
      review:
        "Highly professional and caring physicians. They really listened to my concerns.",
      image: "https://images.pexels.com/photos/15239/flower-roses-red-roses-bloom.jpg?cs=srgb&dl=pexels-pixabay-15239.jpg&fm=jpg",
      rating: 4.8,
    },
  ];

  return (
    <Box
      id="testimonials"
      sx={{
        py: 8,
        px: {xs: theme.spacing(5), md: theme.spacing(12)},
      }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: theme.typography.h1.color,
          mb: theme.spacing(10),
        }}
      >
        Client Reviews
      </Typography>

      {/* Slick Carousel */}
      <Slider {...settings}>
        {reviews.map((review) => (
          <Box key={review.name} sx={{ px: 2 }}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                borderRadius: "16px",
                minHeight: "300px", // Ensures a minimum height for all reviews
              }}
            >
              {/* Avatar and Name */}
              <Avatar
                src={review.image}
                alt={review.name}
                sx={{ width: 80, height: 80, mb: 2 }}
              />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {review.name}
              </Typography>

              {/* Rating */}
              <Rating
                value={review.rating}
                precision={0.5}
                readOnly
                color="#DE0C78"
                icon={<StarIcon fontSize="inherit" sx={{ color: "#DE0C78" }} />}
                emptyIcon={
                  <StarIcon
                    fontSize="inherit"
                    sx={{ color: "#E0E0E0", opacity: 0.55 }}
                  />
                }
                sx={{ my: 2, color: "#DE0C78" }}
              />

              {/* Review Text */}
              <Typography
                variant="body1"
                sx={{ fontStyle: "italic" }}
              >{`"${review.review}"`}</Typography>
            </Paper>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Testimonials;
