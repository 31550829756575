import { Box, Typography, Grid, Paper, useTheme } from "@mui/material";

const Services: React.FC = () => {
  const theme = useTheme();

  const services = [
    {
      name: "Doctor Visits",
      description:
        "Comprehensive healthcare services that involve patient consultations, diagnosis, and treatment plans.",
      specialties: ["General Health", "Preventive Care", "Medical Consultations"],
      procedures: ["Routine Check-ups", "Consultations for Health Concerns"],
      technology: "Basic diagnostic tools and consultation rooms.",
    },
    {
      name: "Orthopaedic Services & Specialties",
      description:
        "A comprehensive range of services to diagnose, treat, and prevent musculoskeletal injuries and conditions.",
      specialties: [
        "Bone and Joint Health",
        "Sports Injuries",
        "Musculoskeletal Disorders",
      ],
      procedures: [
        "PRP Injection",
        "Steroid Injection",
        "Minor Surgeries",
        "Cast Application",
        "Removal of Sutures",
        "Wound Dressings",
      ],
      technology: "Cutting-edge orthopaedic diagnostic tools and treatments.",
    },
    {
      name: "Endoscopies & Colonoscopies",
      description:
        "Diagnostic procedures used to examine the gastrointestinal tract, identify issues, and provide treatment options.",
      specialties: ["Upper Gastrointestinal Endoscopy", "Colonoscopy"],
      procedures: [
        "Upper Gastrointestinal Endoscopy (UGIE)",
        "Colonoscopy",
        "F.I.T Test (Fecal Immunochemical Test)",
        "PEG tube insertion",
        "Bronchoscopy"
      ],
      technology: "Advanced endoscopic tools for minimally invasive procedures.",
    },
    {
      name: "PRP Injection",
      description:
        "Platelet-rich plasma (PRP) injections help the body heal from injuries such as tendinitis and conditions like arthritic knees and ankles.",
      specialties: ["Tendinitis", "Arthritis", "Sports Injuries"],
      procedures: [
        "PRP Injection for Tendinitis",
        "PRP Injection for Arthritic Knees and Ankles",
      ],
      technology: "Centrifuge for platelet separation, growth factors, and cytokines.",
    },
    {
      name: "Steroid Injection",
      description:
        "Steroid injections are used to reduce pain and inflammation in joints, muscles, and soft tissues.",
      specialties: ["Arthritis", "Bursitis", "Muscle and Tendon Pain"],
      procedures: [
        "Steroid Injection for Joint Pain",
        "Steroid Injection for Muscle Pain",
      ],
      technology: "Injection with steroid and anesthetic agent.",
    },
    {
      name: "Haematology Services",
      description:
        "Comprehensive diagnostic and treatment options for a wide range of blood disorders and cancers.",
      specialties: [
        "Cytopenia",
        "Bleeding Disorders",
        "Lymphomas and Leukemias",
        "Solid Tumors",
      ],
      procedures: [
        "Peripheral Blood Smears",
        "Bone Marrow Aspirates and Biopsies",
        "Lumbar Punctures",
        "Transfusion Medicine",
        "Hematopoietic Stem Cell Transplant",
      ],
      technology: "Advanced hematology diagnostic and therapeutic tools.",
    },
    {
      name: "Cardiology Services",
      description:
        "Comprehensive care for the prevention, diagnosis, and treatment of heart-related conditions.",
      specialties: [
        "Heart Failure Management",
        "Arrhythmia Treatment",
        "Hypertension Management",
      ],
      procedures: [
        "Cardiovascular Assessments",
        "Echocardiography",
        "Pre- and Post-Operative Cardiac Care",
      ],
      technology: "Holter Monitoring, ECG, and Stress Testing tools.",
    },
    {
      name: "ENT Services",
      description:
        "Diagnostic, medical, and surgical treatments for ear, nose, and throat conditions.",
      specialties: [
        "Hearing Tests",
        "Sinus Care",
        "Allergy Management",
        "Thyroid and Parathyroid Disorders",
      ],
      procedures: [
        "Tonsillectomy",
        "Septoplasty",
        "Ear Infections Treatment",
        "Snoring and Sleep Apnea Management",
      ],
      technology: "Advanced ENT diagnostic and surgical equipment.",
    },
    {
      name: "OBGYN Services",
      description:
        "Comprehensive health services for women, from routine check-ups to specialized treatments.",
      specialties: [
        "Maternal Health",
        "Contraception Services",
        "Pelvic Organ Prolapse",
      ],
      procedures: [
        "Ultrasound Imaging (Obstetric and Gynecologic)",
        "Pap Smear",
        "Endometrial Sampling",
        "HPV Vaccination",
        "IUCD/IUS Placement and Removal",
      ],
      technology: "Modern ultrasound machines and gynecologic tools.",
    },
    {
      name: "DNA Testing",
      description:
        "Testing services for establishing biological relationships and genetic screening.",
      specialties: ["Paternity Testing", "Genetic Screening"],
      procedures: ["DNA Paternity Testing", "Genetic Health Screening"],
      technology: "State-of-the-art DNA testing kits and laboratory processes.",
    },
  ];

  return (
    <Box
      id="services"
      sx={{
        py: 8,
        px: {xs: theme.spacing(5), md: theme.spacing(12)},
        backgroundColor: "rgba(2,73,117, 0.1)",
      }}
    >
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontFamily: "Avenir Next LT Pro",
          fontWeight: "bold",
          color: theme.typography.h1.color,
        }}
      >
        Our Services
      </Typography>
      <Grid container spacing={4} justifyContent="center" py={8}>
        {services.map((service) => (
          <Grid item xs={12} md={6} lg={4} key={service.name}>
            <Paper
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                height: "100%",
                boxShadow: "none",
                borderRadius: 8,
                borderWidth: 1,
                borderColor: "rgba(2,73,117, 0.5)",
              }}
            >
              <Typography variant="h5" gutterBottom sx={{ color: "#DE0C78" }}>
                {service.name}
              </Typography>
              <Typography gutterBottom>{service.description}</Typography>

              {/* Specialties */}
              <Typography
                variant="h6"
                sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
              >
                Specialties:
              </Typography>
              <ul>
                {service.specialties.map((specialty, index) => (
                  <li key={index}>
                    <Typography>{specialty}</Typography>
                  </li>
                ))}
              </ul>

              {/* Medical Procedures */}
              <Typography
                variant="h6"
                sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
              >
                Medical Procedures:
              </Typography>
              <ul>
                {service.procedures.map((procedure, index) => (
                  <li key={index}>
                    <Typography>{procedure}</Typography>
                  </li>
                ))}
              </ul>

              {/* Technology Used */}
              <Typography
                variant="h6"
                sx={{ mt: 2, mb: 1, fontWeight: "bold" }}
              >
                Technology:
              </Typography>
              <Typography>{service.technology}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
