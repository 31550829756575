import {
  Box,
  Typography,
  Button,
  useTheme,
  Theme,
  useMediaQuery,
  Card,
  Grid,
  CardContent,
  InputAdornment,
  TextField,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import CustomizedInputBase from "./Input";

const useStyles = makeStyles<Theme>((theme) => ({
  heroContainer: {
    position: "relative", // Ensure the container has relative positioning for layering
    backgroundImage: `url(/wallpaper.jpg)`, // Main background image (wallpaper.jpg)
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh", // Full screen height
  },
  overlayImage: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "60%", // Adjust the size of the overlaid image as necessary
    height: "100%",
    zIndex: 2, // Ensure the image is above the wallpaper.jpg
  },
  textContent: {
    zIndex: 3, // Ensure text content stays above all images
    color: "white",
  },
}));

const Hero: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detect if screen size is small

  const [subject, setSubject] = useState("");

   // Handle the enquiry click to open the email client with subject and message
   const handleEnquireClick = () => {
    const email = "draxhallphysio@gmail.com";
    const subjectEncoded = encodeURIComponent(subject); // Ensure subject is properly encoded
    const bodyEncoded = encodeURIComponent("I would like to enguire about your yoga classes"); // Ensure message is properly encoded

    window.location.href = `mailto:${email}?subject=${subjectEncoded}&body=${bodyEncoded}`;
  };
  return (
    <Box
      id="hero"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "auto", // Full screen height
        px: theme.spacing(8),
      }}
    >
      <Card
        sx={{
          width: "100%",
          height: "60%",
          boxShadow: "none",
          borderRadius: 8,
          backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)), url(/images/physio/hero.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          pl: theme.spacing(4),
        }}
      >
        <CardContent
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Vertically center content
            alignItems: "flex-start", // Align to the left
            color: "white",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "white",
              fontWeight: "bold",
              width: { xs: "100%", sm: "100%", md: "60%" },
              fontSize: ["1.5rem", "2rem", "2.5rem", "3rem"]
            }}
          >
            Achieve balance in mind, body and soul.
          </Typography>
          <Typography variant="h6" sx={{ marginTop: 2, color: "white", fontSize: ["0.75rem", "1rem", "1.25rem", "1.5rem"] }}>
            At Drax Hall Physiotherapy & Rehab, we focus on holistic recovery by
            addressing the root causes of pain, not just symptoms. Our expert
            team uses evidence-based techniques to create personalized treatment
            plans that improve mobility, reduce pain, and enhance function,
            helping you lead a more active life. Through manual therapy,
            tailored exercises, and advanced technologies, we support long-term
            healing and wellness.
          </Typography>
        </CardContent>
      </Card>

      <Grid container spacing={3} sx={{ mt: 0.5 }} height={{xs: "150vh",  md: "50vh"}}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              height: "100%", // Make sure the card takes the full height of the grid item
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end", // Center the content vertically
              alignItems: "flex-start", // Center the content horizontally
              boxShadow: "none",
              p: 3, // Add padding inside the card
              borderRadius: 8,
              backgroundImage:
                "linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)), url(https://cdn.mos.cms.futurecdn.net/WczdfBVdCBZS8L2ZFijL9U.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Typography variant="h6" color={"white"}>
              Improved Flexibility
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              height: "100%", // Make sure the card takes the full height of the grid item
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end", // Center the content vertically
              alignItems: "flex-start", // Center the content horizontally
              boxShadow: "none",
              p: 3, // Add padding inside the card
              borderRadius: 8,
              backgroundImage:
                "linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)), url(https://img.freepik.com/free-photo/man-training-with-ball-full-shot_23-2149178002.jpg?t=st=1734200206~exp=1734203806~hmac=fb1eb834602e4fc0c95f7e6a28e37aa15e3a5f555bf3b26bb62fbc6827ad2e22&w=740)",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Typography variant="h6" color={"white"}>
              Stress Reduction
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
