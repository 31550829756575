import React from "react";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

const EndoscopyColonoscopySection: React.FC = () => {
  const theme = useTheme();

  const imageUrls = [
    "https://colorectalcancer.org/sites/default/files/styles/fifty_fifty/public/media/images/patient%20looking%20at%20computer.png.webp?itok=mMXXHqyx",
    "/images/medical/endo.jpg",
    "https://www.shutterstock.com/image-photo/instruments-diagnostic-endoscopy-closeup-doctor-600nw-2460576327.jpg",
    "https://t3.ftcdn.net/jpg/04/96/66/94/360_F_496669474_cWgyYxYF0uqB6XNejuAhUzjO0GgdYVIP.jpg",
  ];

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        py: 8,
        px: { xs: 4, sm: 8, md: 12 },
        textAlign: "center",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        borderRadius: 2,
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
        Endoscopy and Colonoscopy
      </Typography>
      <Typography
        variant="body1"
        paragraph
        sx={{ color: "text.secondary", px: { xs: 5, sm: 10, md: 15 }, my: 5 }}
      >
        Endoscopy and colonoscopy are essential procedures for diagnosing
        gastrointestinal conditions. Whether you're dealing with digestive
        discomfort or need a routine screening, these exams help us understand
        what's happening inside your body.
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {imageUrls.map((url, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box
              sx={{
                width: "100%",
                height: {xs: 300, md: 200},
                backgroundImage: `url(${url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          </Grid>
        ))}
      </Grid>

      {/* Link to full page */}
      <Button
        variant="contained"
        component="a"
        href="/endoscopy"
        sx={{
          fontSize: "1.2rem",
          textTransform: "none",
          py: 1.5,
          mt: 5,
          px: 4,
          backgroundColor: "transparent",
          color: "ButtonText",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        endIcon={<ArrowForward />}
      >
        Learn More
      </Button>
    </Box>
  );
};

export default EndoscopyColonoscopySection;
